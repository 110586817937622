import React from "react"

const defaultContext = {
  language: "english",
  mobileMenuOpen: false,
  overlayVisible: false,
  setLanguage: () => {},
  toggleLanguage: () => {},
  getLanguage: () => {},
  toggleMobileMenu: () => {},
  closeMobileMenu: () => {},
  setFormAnswer: () => {}, // Method to update form answers
  getFormAnswer: () => {}, // Method to get form answers
  resetFormAnswers: () => {}, // Method to reset form answers
  showOverlay: () => {}, // Method to show overlay
  hideOverlay: () => {}, // Method to hide overlay
  setCurrentPage: () => {}, // Method to set current page name
  getCurrentPage: () => {}, // Method to get current page name
}

export const AppStateContext = React.createContext(defaultContext)

const isBrowser = typeof window !== "undefined"

const localStorageKeys = {
  lang: "ca.lifeyourlifems.lang",
}

const getLocalStorageItem = key => {
  return isBrowser ? window.localStorage.getItem(key) : null
}

const setLocalStorageItem = (key, value) => {
  isBrowser && window.localStorage.setItem(key, value)
}

const getDefaultLanguage = () => {
  if (isBrowser) {
    const browserLanguage = navigator.language || navigator.userLanguage
    if (browserLanguage.startsWith("fr-")) {
      return "french"
    }
    if (window.location.hostname.includes("vivresaviesp.ca")) {
      return "french"
    }
  }

  return "english"
}

export class AppStateProvider extends React.Component {
  constructor(props) {
    super(props)

    let language = getLocalStorageItem(localStorageKeys.lang)
    if (!language || !["english", "french"].includes(language)) {
      language = getDefaultLanguage()
    }

    this.state = {
      language: language,
      mobileMenuOpen: false,
      formAnswers: {}, // Initialize empty formAnswers
      overlayVisible: false, // State to manage overlay visibility
      currentPage: "test",
    }
    this.loadLanguage(language)
  }

  setLanguage = language => {
    if (!language || !["english", "french"].includes(language)) {
      language = "english"
    }

    this.setState({
      language: language,
    })

    setLocalStorageItem(localStorageKeys.lang, language)
    this.loadLanguage(language)
  }

  loadLanguage = (language, retryCount = 0) => {
    if (isBrowser && window.OneTrust) {
      const langCode = language === "french" ? "fr-ca" : "en"
      window.OneTrust.changeLanguage(langCode)
    } else if (retryCount < 10) {
      const loadLanguage = this.loadLanguage
      setTimeout(function () {
        loadLanguage(language, retryCount + 1)
      }, (2 + retryCount) * 50)
    }
  }

  toggleLanguage = () => {
    const language = this.state.language === "french" ? "english" : "french"
    this.setLanguage(language)
  }

  toggleMobileMenu = () => {
    const mobileMenuOpen = this.state.mobileMenuOpen === false ? true : false
    this.setState({
      mobileMenuOpen: mobileMenuOpen,
    })

    const html = document.getElementsByTagName("html")[0]
    if (mobileMenuOpen) html.style.overflow = "hidden"
    else html.style.overflow = "auto"
  }

  closeMobileMenu = () => {
    setTimeout(() => {
      this.setState({
        mobileMenuOpen: false,
      })
    }, 50)
    const html = document.getElementsByTagName("html")[0]
    html.style.overflow = "auto"
  }

  // Method to set an individual form answer
  setFormAnswer = (questionKey, value) => {
    this.setState(prevState => ({
      formAnswers: {
        ...prevState.formAnswers,
        [questionKey]: value, // Dynamically set the answer by question key
      },
    }))
  }

  // Method to retrieve an individual form answer
  getFormAnswer = questionKey => {
    return this.state.formAnswers[questionKey]
  }

  // Method to reset all form answers
  resetFormAnswers = () => {
    this.setState({
      formAnswers: {},
    })
  }

  showOverlay = () => {
    this.setState({ overlayVisible: true })
  }

  hideOverlay = () => {
    this.setState({ overlayVisible: false })
  }

  setCurrentPage = pageName => {
    this.setState({ currentPage: pageName })
  }

  getCurrentPage = () => {
    return this.state.currentPage
  }

  getValue = () => {
    return {
      ...this.state,
      setLanguage: this.setLanguage,
      toggleLanguage: this.toggleLanguage,
      toggleMobileMenu: this.toggleMobileMenu,
      closeMobileMenu: this.closeMobileMenu,
      setFormAnswer: this.setFormAnswer, // Pass form answer methods to context
      getFormAnswer: this.getFormAnswer,
      resetFormAnswers: this.resetFormAnswers,
      showOverlay: this.showOverlay, // Pass overlay methods to context
      hideOverlay: this.hideOverlay,
      setCurrentPage: this.setCurrentPage,
      getCurrentPage: this.getCurrentPage,
    }
  }

  render() {
    const value = this.getValue()
    if (this.props.url === "/index_fr.html") {
      value.language = "french"
    }
    return (
      <AppStateContext.Provider value={value}>
        {this.props.children}
      </AppStateContext.Provider>
    )
  }
}

export function wrapRootElement({ element }) {
  return <AppStateProvider url={element.props.url}>{element}</AppStateProvider>
}
